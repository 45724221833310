import watchElements from '~scripts/utils/watchElements'

const elements = new Set<HTMLElement>()

const update = (el: HTMLElement): void => {
  el.style.marginRight = ''
  el.style.marginLeft = ''
  const flushRight = el.classList.contains('flush-right')
  if (flushRight) {
    el.style.marginRight = '0px'
    const { right } = el.getBoundingClientRect()
    const marginRight = right + pageXOffset - window.innerWidth
    el.style.marginRight = marginRight + 'px'
  } else {
    el.style.marginLeft = '0px'
    const { left } = el.getBoundingClientRect()
    const marginLeft = -left + pageXOffset
    el.style.marginLeft = marginLeft + 'px'
  }
}
const updateAll = (): void => {
  for (const el of elements) {
    update(el)
  }
}

watchElements<HTMLElement>(
  '.flush-left, .flush-right',
  {},
  {
    mount(el) {
      elements.add(el)
      update(el)
    },
    update,
    unmount(el) {
      el.style.marginLeft = ''
      el.style.marginRight = ''
      elements.delete(el)
    },
  }
)
window.addEventListener('resize', updateAll)
