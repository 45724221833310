import watchElements from '~scripts/utils/watchElements'

const setPadding = (el: HTMLImageElement) => {
  const pb =
    parseFloat(el.getAttribute('height')!) /
    parseFloat(el.getAttribute('width')!)
  el.parentElement!.style.paddingBottom = pb * 100 + '%'
  el.parentElement!.style.height = '0px'
}

const onErrorImage = (e: Event) => {
  const wrap = (e.target as HTMLImageElement).parentElement!
  wrap.classList.remove('load-success')
  wrap.classList.add('load-error')
}

const onLoadImage = (e: Event) => {
  const wrap = (e.target as HTMLImageElement).parentElement!
  wrap.classList.remove('load-error')
  wrap.classList.add('load-success')
}

watchElements(
  '.fluid-img-wrap > img[width][height]',
  {
    attributeFilter: ['width', 'height'],
  },
  {
    mount: (el: HTMLImageElement) => {
      el.addEventListener('load', onLoadImage)
      el.addEventListener('error', onErrorImage)
      setPadding(el)
    },
    update: (el: HTMLImageElement) => {
      setPadding(el)
    },
    unmount: (el: HTMLImageElement) => {
      el.removeEventListener('load', onLoadImage)
      el.removeEventListener('error', onErrorImage)
    },
  }
)

document.body.addEventListener('load', (e) => {
  console.log(e.target)
})
