import {
  useSelector as useSelectorGeneral,
  useDispatch as useDispatchGeneral,
} from 'react-redux'
import { AppState, AppAction } from './types'
import { Dispatch, Action } from 'redux'

export const useSelector = <TSelected = unknown>(
  selector: (state: AppState) => TSelected,
  equalityFn?: ((left: TSelected, right: TSelected) => boolean) | undefined
): TSelected => {
  return useSelectorGeneral<AppState, TSelected>(selector, equalityFn)
}

export const useDispatch = <T extends AppAction = AppAction>(): Dispatch<T> =>
  useDispatchGeneral()
