// Filter
$(document).ready(function(){
  
  if ($('.filter-outer').length) {
    
    // Prüfen, ob Items ausgegeben werden
    $('.filter-outer').each(function () {
      if ($(this).find('.filter-item:visible').length) {
        $(this).find('.no-results').hide();
      } else {
        $(this).find('.no-results').show();
      }
      // Gleiche Höhe für sichtbare Header bei Zeilen 
      if ($(this).find('.filter-item:visible').length > 1) {
        if ($(this).find('.teaser-lined').length) {
          $(this).find('.teaser-lined-head:visible').matchHeight();
          $(this).find('.teaser-lined-sub:visible').matchHeight();
        } else if ($(this).find('.teaser-news').length) {
          $(this).find('.teaser-news-head:visible').matchHeight();
        }
      }
    });
            
    // Selects für Filter
    var $filterSelect = $('select.select-filter');
      
    // Filter Product
    $filterSelect.change( function() {
          
      // Filter-Section
      var $filter = $(this).closest('.filter-outer');
    
      // Filter-Items und Filter-Sections
      var $filterItems = $filter.find('.filter-item');    
                
      var $filterValue = this.value;
      
      // Items mit Filter prüfen    
      $filterItems.each(function () {
              
        if ($filterValue.length) {
          // Es ist ein Filter gesetzt
          if ($(this).hasClass('item-'+$filterValue)) {
            // Filter passt
            $(this).show();
          } else {
            // Filter passt nicht
            $(this).hide();
          }
        } else {
          // Es ist kein Filter gesetzt
          $(this).show(); 
        }
  
        // Prüfen, ob Items ausgegeben werden
        if ($filter.find('.filter-item:visible').length) {
          $filter.find('.no-results').hide();
        } else {
          $filter.find('.no-results').show();
        }
      
      });
      
      // Update MatchHeight
      if ($filter.find('.teaser-lined').length) {
        if ($filter.find('.filter-item:visible').length > 1) {
          $filter.find('.teaser-lined-head:visible').matchHeight();
          $filter.find('.teaser-lined-sub:visible').matchHeight();
        } else {
          $filter.find('.teaser-lined-head').matchHeight({ remove: true });
          $filter.find('.teaser-lined-sub').matchHeight({ remove: true });
        }
      } else if ($filter.find('.teaser-news').length) {
        if ($filter.find('.filter-item:visible').length > 1) {
          $filter.find('.teaser-news-head:visible').matchHeight();
        } else {
          $filter.find('.teaser-news-head').matchHeight({ remove: true });
        }
      }
      
    
    });

    // Filter reset
    $('.reset-filter').click(function(e) {
      e.preventDefault();
      
      // Filter-Section
      var $filter = $(this).closest('.filter-outer');
      
      // Selects für Filter
      var $filterSelect = $filter.find('select.select-filter');
      
      $filterSelect.val('');
      $filterSelect.change();
      $filterSelect.selectpicker('refresh');
    });	

  }
    
});

