import React, { useState, useMemo, useCallback, useEffect } from 'preact/compat'
import { AccordionSliderDefinition } from './AccordionSliderDefinition'
import DesktopSlide from './DesktopSlide'
import HTMLNode from '~scripts/components/HTMLNode'
import Swiper from 'swiper'

type AccordionSliderProps = {
  definition: AccordionSliderDefinition
}
const AccordionSlider: React.FunctionComponent<AccordionSliderProps> = ({
  definition,
}) => {
  const [current, _setCurrent] = useState(0)
  const slideCount = definition.slides.length
  const animation = useMemo(() => {
    let cur = 0
    _setCurrent(cur)
    return $({
      get current(): number {
        return cur
      },
      set current(nextValue: number) {
        cur = nextValue % slideCount
        _setCurrent(cur)
      },
    })
  }, [_setCurrent])
  const advance = useCallback(() => {
    const _cur = Math.floor(animation.get(0).current)
    animation
      .stop()
      .animate(
        { current: _cur + 1 },
        { duration: 10000, done: advance, easing: 'linear' }
      )
  }, [animation])
  useEffect(() => advance(), [advance])
  const setCurrent = useCallback(
    (nextValue: number, duration: number = 0) => {
      animation
        .stop()
        .animate({ current: nextValue }, { duration, done: advance })
    },
    [animation, definition]
  )

  const { syncTo } = definition
  const swiper: Swiper = syncTo ? syncTo.swiper : undefined
  const currentInt = Math.floor(current)
  useEffect(() => {
    if (swiper) {
      swiper.slideTo(currentInt + 1)

      const handleChange = () => {
        if (swiper.realIndex !== currentInt) {
          setCurrent(swiper.realIndex)
        }
      }
      swiper.on('slideChange', handleChange)

      return () => swiper.off('slideChange', handleChange)
    }
  }, [swiper, currentInt])

  return (
    <>
      {definition.slides.map((slide, i) => (
        <DesktopSlide
          current={current}
          setCurrent={setCurrent}
          index={i}
          key={i}
          title={<HTMLNode node={slide.title} />}
          content={<HTMLNode node={slide.content} />}
        />
      ))}
    </>
  )
}

export default AccordionSlider
