let keyIndex = 0
const generateKey = (): string => {
  keyIndex++
  return `menuItem-${keyIndex}`
}

export interface MenuItem {
  label: string
  href?: string
  className: string
  id?: string
  key: string
  target?: string

  parent?: MenuItem
  children?: MenuItem[]
  aside?: HTMLElement
}
export interface MenuDefinition {
  left: HTMLElement | null
  mainMenu?: MenuItem
  footerMenu?: MenuItem[]
  search?: HTMLElement
}
export const menuFromDOM = (wrap: HTMLElement): MenuDefinition => {
  const readUl = (
    el: HTMLUListElement | null,
    parent?: MenuItem
  ): MenuItem[] | undefined => {
    if (!el) {
      return
    }
    const result = Array.from(el.children).map(
      (li: HTMLLIElement): MenuItem => {
        const result: MenuItem = {
          label: '',
          key: generateKey(),
          href: undefined,
          className: li.getAttribute('class') || '',
          id: li.getAttribute('id') || undefined,
          parent,
          children: undefined,
          aside: undefined,
        }
        const link = li.firstElementChild
        if (link && !(link.tagName === 'UL')) {
          result.label = (link.textContent || '').trim()
          result.href = link.getAttribute('href') || undefined
          result.target = link.getAttribute('target') || undefined
        }
        const submenu = li.getElementsByTagName('ul')[0]
        if (submenu) {
          result.children = readUl(submenu, result)
        }
        result.aside = Array.from(li.children).filter((el) =>
          el.classList.contains('mainmenu-aside')
        )[0] as HTMLElement | undefined
        return result
      }
    )
    return result.length ? result : undefined
  }

  const mainMenu: MenuItem = {
    label: 'root',
    key: generateKey(),
    href: '',
    className: 'd-none',
    children: [],
  }
  const menu = wrap.querySelector('.js-menu')
  if (menu) {
    mainMenu.children = readUl(wrap.querySelector('ul'), mainMenu)
    mainMenu.aside = Array.from(menu.children).filter((el) =>
      el.classList.contains('mainmenu-aside')
    )[0] as HTMLElement | undefined
  }

  const result: MenuDefinition = {
    left: wrap.querySelector('.js-menuleft'),
    mainMenu,
    footerMenu: readUl(wrap.querySelector('ul.js-menufooter')),
    search: wrap.querySelector<HTMLElement>('.js-search') || undefined,
  }

  return result
}
