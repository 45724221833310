function htmldecode(encodedString) {
    var parser = new DOMParser
    var dom = parser.parseFromString('<!doctype html><body>' + encodedString, 'text/html')
    return dom.body.textContent;
}

$('.calculator-counter [data-action]').on('click', (e) => {
    let btn = $(e.currentTarget)
    let counter = btn.parent()
    let input = counter.find('input')
    let value = input.val() ? parseInt(input.val().toString()) : 0

    if(btn.attr('data-action') == 'remove' && value > 0) {
        value--
    } else if(btn.attr('data-action') == 'add') {
        value++
    }

    // go back to placeholder if 0
    if(value == 0) {
        input.val('')
    } else {
        input.val(value)
    }
})

$('#calculator form').on('submit', (e) => {
    e.preventDefault()

    let resultContainer = $('#calculator-result')
    let currentView = resultContainer.attr('data-show')

    let input1 = $('#calculator-input-1')
    let input2 = $('#calculator-input-2')
    let input3 = $('#calculator-input-3')

    if(!input1.val() || parseInt(input1.val().toString()) < 0) {
        input1.focus()

        if(currentView != 1) {
            resultContainer.attr('data-show', 0)
            setTimeout(() => {
                resultContainer.attr('data-show', 1)
            }, 400)
        }

        return;
    }
    if(!input2.val() || parseInt(input2.val().toString()) < 0) {
        input2.focus()

        if(currentView != 1) {
            resultContainer.attr('data-show', 0)
            setTimeout(() => {
                resultContainer.attr('data-show', 1)
            }, 400)
        }

        return;
    }
    if(!input3.val() || parseInt(input3.val().toString()) < 0) {
        input3.focus()

        if(currentView != 1) {
            resultContainer.attr('data-show', 0)
            setTimeout(() => {
                resultContainer.attr('data-show', 1)
            }, 400)
        }

        return;
    }

    if($(window).outerWidth() < 992) {
        $('html, body').animate({
            scrollTop: resultContainer.offset().top - ($('.navbar').eq(0).outerHeight() || 0),
        }, 1000)
    }

    let daysLeft = Math.floor(parseInt(input1.val().toString()) * 130 / 6 / parseInt(input2.val().toString()) / parseInt(input3.val().toString()))
    let monthlyUsage = Math.ceil(parseInt(input2.val().toString()) * parseInt(input3.val().toString()) * 6 * 30 / 130)

    resultContainer.attr('data-show', 0)
    setTimeout(() => {
        if(daysLeft == 1)
            resultContainer.find('.calculator-result-days').text(calculatorText.day)
        else
            resultContainer.find('.calculator-result-days').text(daysLeft + ' ' + calculatorText.days)

        resultContainer.find('.calculator-result-monthly span').text(monthlyUsage)

        if(daysLeft < 5) {
            resultContainer.find('.calculator-result-icon').html('<i class="fal fa-exclamation-circle text-danger"></i>')
            resultContainer.find('.calculator-result-heading').html(htmldecode(calculatorText.headingCritical))
            if(daysLeft > 0) {
                resultContainer.find('.calculator-result-text').html(htmldecode(calculatorText.textCritical))
            } else {
                resultContainer.find('.calculator-result-text').html(htmldecode(calculatorText.textBarely))
            }
            resultContainer.attr('data-show', 2)
        } else{
            resultContainer.find('.calculator-result-icon').html('<i class="fal fa-check-circle text-success"></i>')
            resultContainer.find('.calculator-result-heading').html(htmldecode(calculatorText.headingGood))
            resultContainer.find('.calculator-result-text').html(htmldecode(calculatorText.textGood))
            resultContainer.attr('data-show', 3)
        }
    }, 400)
})

if($('#calculator').length) {
    $(window).on('resize orientationchange', () => {
        if($(window).outerWidth() >= 992) {
            $('#calculator .curved').attr('data-shape', 'hero-title-left')
        } else {
            $('#calculator .curved').attr('data-shape', 'left')
        }
    }).trigger('resize')

    $('#calculator-result').attr('data-show', 1)
}