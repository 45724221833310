import $ from 'jquery'

const scaleAnimators = new WeakMap<HTMLElement, { scale: 1 }>()

const animateScale = (
  el: HTMLElement,
  to: number,
  duration = 400,
  easing?: string
): void => {
  if (!el) {
    return
  }
  if (!scaleAnimators.has(el)) {
    scaleAnimators.set(el, { scale: 1 })
  }
  $(scaleAnimators.get(el)!)
    .stop(false, false)
    .animate(
      {
        scale: to,
      },
      {
        duration,
        easing,
        step(scale) {
          $(el)
            .css('transform', `scale(${scale})`)
            .css('webkitTransform', `scale(${scale})`)
        },
      }
    )
}

const enableScaleAnimation = (
  selector: string,
  childSelector = 'img'
): void => {
  $('body').on('mouseover', selector, function (this: HTMLElement) {
    if ($(this).hasClass('hover')) {
      return
    }
    $(this).addClass('hover')
    animateScale($(this).find(childSelector).get(0), 1.5, 30000, 'linear')
  })

  $('body').on('mouseout', selector, function (this: HTMLElement) {
    if (!$(this).hasClass('hover')) {
      return
    }
    $(this).removeClass('hover')
    animateScale($(this).find(childSelector).get(0), 1)
  })
}

enableScaleAnimation('a.card', '.card-img-top img')
