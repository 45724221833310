import React, { useEffect, useMemo, useCallback } from 'preact/compat'
import FooterMenu from './FooterMenu'
import MainLayout from './MainLayout'
import { useSelector, useDispatch } from './store/hooks'
import HTMLNode from '../../components/HTMLNode'
import MenuStack from './MenuStack'
import { MenuItem } from './menuDefinition'
import MotionDiv from '~scripts/components/MotionDiv'
import AnimatePresence from '~scripts/components/AnimatePresence'
import useSizeObserver from '~scripts/utils/useSizeObserver'

const MainMenu: React.FunctionComponent = () => {
  const { left, mainMenu, search: searchNode } = useSelector(
    (state) => state.definition
  )
  const isMobile = useSelector((state) => state.ui.mobileMenu)
  const { location, search } = useSelector((state) => state.ui)
  const right = useMemo(() => {
    let loc = location
    while (loc && !loc.aside && loc.parent) {
      loc = loc.parent
    }
    if (!loc || !loc.aside) {
      return undefined
    }
    return {
      key: 'aside-' + loc.key,
      el: loc.aside,
      wide: !loc.children || !loc.children.length,
    }
  }, [location])

  const dispatch = useDispatch()
  useEffect(() => {
    document.body.classList.toggle('mobile-menu', isMobile)
  }, [isMobile])
  useEffect(() => {
    const getLocation = (el: HTMLElement): MenuItem | undefined => {
      if (!el.closest('#mainmenu-desktop')) {
        return undefined
      }
      const li = el.closest('li')!
      const index = Array.from(li.parentElement!.children).indexOf(li)
      const menuItem = mainMenu && mainMenu.children && mainMenu.children[index]
      return menuItem
    }
    const onClick = (e: MouseEvent) => {
      const link =
        e.target &&
        (e.target as HTMLElement).closest<HTMLAnchorElement>('a[href="#"]')
      const inMenu = !!link && !!link.closest('#mainmenu-desktop')
      if (link && inMenu) {
        e.preventDefault()
        const menuItem = getLocation(link)
        const open =
          menuItem &&
          menuItem.children &&
          menuItem.children.length &&
          menuItem !== location
        dispatch({
          type: 'ui/set_location',
          location: open ? menuItem : undefined,
        })
      } else if ((e.target as HTMLElement).closest('a[href="#mainmenu"]')) {
        e.preventDefault()
        dispatch({
          type: 'ui/set_location',
          location: location ? undefined : mainMenu,
        })
      } else if ((e.target as HTMLElement).closest('.js-toggle-search')) {
        e.preventDefault()
        dispatch({
          type: 'ui/set_search',
          state: !search,
        })
      }
    }
    document.body.classList.toggle('menu-open', !!location || search)
    for (const el of document.querySelectorAll<HTMLElement>(
      '#mainmenu-desktop .nav-item'
    )) {
      const target = getLocation(el)
      let loc = location
      let active = false
      while (loc && loc.parent) {
        active = active || loc === target
        loc = loc.parent
      }
      el.classList.toggle(
        'active',
        search || location ? active : el.classList.contains('current-page')
      )
    }
    document.body.addEventListener('click', onClick, { passive: false })
    return () => document.body.removeEventListener('click', onClick)
  }, [mainMenu, location, search])
  const close = useCallback(
    () =>
      dispatch({
        type: 'ui/set_location',
        location: undefined,
      }),
    [dispatch]
  )

  const [footerWidth, footerHeight, footerRef] = useSizeObserver()

  return (
    <div id="mm-wrap">
      <AnimatePresence>
        {(!!location || search) && (
          <MotionDiv
            className="backdrop"
            key="backdrop"
            onClick={close}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ type: 'tween' }}
          />
        )}
        {(!!location || search) && (
          <MotionDiv
            className="mm-menu d-flex flex-column"
            style={{ maxHeight: '100%' }}
            key="menu"
            initial={{ opacity: 0, y: '-100%' }}
            animate={{ opacity: 1, y: '0px' }}
            exit={{ opacity: 0, y: '-100%' }}
            transition={{ type: 'tween' }}
          >
            <div
              style={{
                flexShrink: 1,
                overflow: 'auto',
                maxHeight: window.innerHeight - footerHeight - 100 + 'px',
              }}
            >
              <MainLayout>
                <HTMLNode node={left} />
                <MenuStack />
                {right ? (
                  <div
                    class={'menu-aside ' + right.wide ? 'menu-aside-wide' : ''}
                    key={right ? right.key : 'empty'}
                  >
                    <HTMLNode node={right.el} />
                  </div>
                ) : (
                  <div />
                )}
              </MainLayout>
            </div>
            <div class="container" ref={footerRef}>
              <FooterMenu />
            </div>
          </MotionDiv>
        )}
      </AnimatePresence>
    </div>
  )
}

export default MainMenu
