import $ from 'jquery'

const getHash = (url = ''): string => {
  return url.split('#')[1] || ''
}

const updateWorldMap = (map: JQuery<HTMLElement>, target?: string) => {
  const wrap = map.closest('.worldmap-wrap')
  const nextTarget =
    typeof target === 'undefined' ? map.data('current-pin') : target
  map.data('current-pin', nextTarget)

  map.find('a.worldmap-pin').each(function (this: HTMLAnchorElement) {
    $(this).toggleClass(
      'worldmap-pin--active',
      getHash(this.href) === nextTarget
    )
  })

  wrap.find('.worldmap-card').each(function (this: HTMLElement) {
    $(this).toggleClass('worldmap-card--active', this.id === nextTarget)
  })
}

$('body').on('click', 'a.worldmap-pin', function (this: HTMLElement, e) {
  e.preventDefault()

  const pin = $(this).closest('a')
  const target = getHash(pin.attr('href'))
  const map = pin.closest('.worldmap')

  const nextTarget = map.data('current-pin') === target ? '' : target
  updateWorldMap(map, nextTarget)
})

$('body').on('click', '.worldmap-wrap', function (this: HTMLElement, e) {
  if ($(e.target).closest('a.worldmap-pin, .worldmap-card').length > 0) {
    return
  }
  updateWorldMap($(this).find('.worldmap'), '')
})

$('body').on('click', '.worldmap-card .card-close', function (
  this: HTMLElement,
  e
) {
  e.preventDefault()
  updateWorldMap($(this).closest('.worldmap-wrap').find('.worldmap'), '')
})
