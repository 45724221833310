import React, { useMemo } from 'preact/compat'
import { useSelector } from './store/hooks'
import MenuList from './MenuList'
import useSizeObserver from '../../utils/useSizeObserver'
import MotionDiv from '~scripts/components/MotionDiv'
import AnimatePresence from '~scripts/components/AnimatePresence'

const MenuStack: React.FunctionComponent = () => {
  const mobileMenu = useSelector((state) => state.ui.mobileMenu)
  const location = useSelector((state) => state.ui.location)
  const pages = useMemo(() => {
    if (!location) {
      return []
    }
    const result = [location]
    while (!!result[result.length - 1].parent) {
      result.push(result[result.length - 1].parent!)
    }
    result.reverse()
    if (!mobileMenu) {
      result.splice(0, 1)
    }
    return result.filter((x) => !!(x.children && x.children.length))
  }, [location, mobileMenu])
  const currentPage = pages[pages.length - 1]

  const [_width, height, currentRef] = useSizeObserver()

  return (
    <div class="menu-stack" style={{ position: 'relative', height }}>
      <AnimatePresence>
        {pages.map((page, i) => (
          <MotionDiv
            key={page.key}
            initial={{
              opacity: 1,
              visibility: 'visible',
              marginLeft: i ? '30%' : '0px',
            }}
            animate={
              page === currentPage
                ? { opacity: 1, visibility: 'visible', marginLeft: '0px' }
                : { opacity: 1, visibility: 'visible', marginLeft: '-30%' }
            }
            transition={{ type: 'tween' }}
            exit={{ opacity: 1, visibility: 'visible' }}
          >
            <div ref={page === currentPage ? currentRef : undefined}>
              <MenuList menuItem={page} />
            </div>
          </MotionDiv>
        ))}
      </AnimatePresence>
    </div>
  )
}

export default MenuStack
