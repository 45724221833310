import React from 'preact/compat'
import { useSelector } from './store/hooks'

const FooterMenu: React.FunctionComponent = () => {
  const menu = useSelector((state) => state.definition.footerMenu)
  if (!menu) {
    return <></>
  }
  return (
    <div class="mainmenu-navbar-footer bg-primary">
      <ul class="nav flex-row js-menufooter">
        {menu.map((item, key) => (
          <li class={'nav-item ' + item.className} key={key}>
            <a class="nav-link" id={item.id} href={item.href}>
              {item.label}
            </a>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default FooterMenu
