import $ from 'jquery'

$('body').on('click', '.side-buttons a', function (this: HTMLAnchorElement, e) {
  $(this).blur()
  const selector = $(this).data('scrollto')
  let element = $(selector).eq(0)
  if (element.is('section')) {
    const child = element.find('.container').eq(0)
    element = child.length ? child : element
  }
  if (!element.length) {
    return
  }
  e.preventDefault()
  $('html, body').animate(
    {
      scrollTop:
        element.offset()!.top - ($('.navbar').eq(0).outerHeight() || 0),
    },
    {
      duration: 1000,
    }
  )
})
