export type AccordionSliderDefinition = {
  slides: {
    title?: HTMLElement
    content?: HTMLElement
  }[]
  syncTo?: HTMLElement
}

export const definitionFromDOM = (
  el: HTMLElement
): AccordionSliderDefinition => {
  const slides = Array.from(el.children)
    .map((child) => {
      const title = Array.from(child.children).filter((x) =>
        x.classList.contains('slide-title')
      )[0] as HTMLElement | undefined
      const content = Array.from(child.children).filter((x) =>
        x.classList.contains('slide-content')
      )[0] as HTMLElement | undefined
      return {
        title,
        content,
      }
    })
    .filter((x) => !!x.title || !!x.content)
  Array.from(el.children).forEach((x) => el.removeChild(x))
  const synctoId = el.getAttribute('data-syncto')
  return {
    slides,
    syncTo: synctoId
      ? document.getElementById(synctoId) || undefined
      : undefined,
  }
}
