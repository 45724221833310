// import videojs from 'video.js/dist/alt/video.core.novtt.js'
// import MediaElementPlayer from 'mediaelement/src/js/player'
// import de from 'video.js/dist/lang/de.json'
// import { getLanguageCode } from '~scripts/i18n'
// videojs.addLanguage('de', de)

import watchElements from '~scripts/utils/watchElements'

const initVideo = (el: HTMLVideoElement): void => {
  // el.classList.add('vjs-default-skin')
  // const player = videojs(el, {
  //   aspectRatio: '16:9',
  //   language: getLanguageCode(),
  // })
  // new MediaElementPlayer(el)
}

Array.from(document.querySelectorAll('video.video-js')).forEach(initVideo)

watchElements(
  '.video-js',
  {},
  {
    mount(el) {
      const video = el.querySelector('video')
      if (!video) {
        return
      }
      video.controls = false
      const overlay = document.createElement('div')
      overlay.setAttribute('class', 'video-js-overlay')
      const btn = document.createElement('button')
      btn.setAttribute(
        'class',
        'btn btn-lg btn-primary btn-icon rounded-circle shadow-sm'
      )
      btn.innerHTML = '<i class="fal fa-play"></i>'
      overlay.appendChild(btn)
      el.appendChild(overlay)

      video.addEventListener('play', () => {
        overlay.classList.add('overlay--hidden')
        video.controls = true
      })
      btn.addEventListener('click', (e) => {
        e.preventDefault()
        e.stopPropagation()
        video.play()
      })
    },
    unmount(el) {
      for (const overlay of el.querySelectorAll('.video-js-overlay')) {
        overlay.parentElement!.removeChild(overlay)
      }
    },
  }
)
