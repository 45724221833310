import { definitionFromDOM } from './AccordionSliderDefinition'
import { render } from 'preact'
import React from 'preact/compat'
import AccordionSlider from './AccordionSlider'

const accordionSlider = (el: HTMLElement): void => {
  const definition = definitionFromDOM(el)

  render(
    <AccordionSlider definition={definition} />,
    el,
    el.lastChild as Element
  )
}

export default accordionSlider
