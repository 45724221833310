import ResizeObserver from 'resize-observer-polyfill'
import { useState, useCallback, useMemo } from 'preact/compat'

const useSizeObserver = (): [
  number,
  number,
  (el: HTMLElement | null) => void
] => {
  const [height, setHeight] = useState<number>(0)
  const [width, setWidth] = useState<number>(0)
  const resizeObserver = useMemo(
    () =>
      new ResizeObserver((entries, observer) => {
        const height = entries[0] ? entries[0].contentRect.height : 0
        const width = entries[0] ? entries[0].contentRect.width : 0
        setHeight(height)
        setWidth(width)
      }),
    []
  )
  const ref = useCallback((el: HTMLElement | null): void => {
    if (el) {
      resizeObserver.disconnect()
      resizeObserver.observe(el)
      const { width, height } = el.getBoundingClientRect()
      setWidth(width)
      setHeight(height)
    }
  }, [])
  return [width, height, ref]
}

export default useSizeObserver
