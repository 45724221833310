import $ from 'jquery'
import 'parsleyjs/dist/parsley.js'
import 'parsleyjs/dist/i18n/de.js'
import 'parsleyjs/dist/i18n/pl.js'
import 'parsleyjs/dist/i18n/fr.js'
import 'parsleyjs/dist/i18n/it.js'
import 'parsleyjs/dist/i18n/nl.js'
import { getLanguageCode } from '../i18n'

const parsleyLocales = ['en', 'de', 'pl', 'fr', 'it', 'nl']

const language = getLanguageCode()
if (parsleyLocales.indexOf(language) >= 0) {
  window.Parsley.setLocale(language)
}

$.extend<ParsleyOptions, Partial<ParsleyOptions>>(window.Parsley.options, {
  classHandler({ $element }) {
    if ($element.is('select') && $element.parent().is('.bootstrap-select')) {
      return $element.parent()
    }
    return $element
  },
  errorsContainer({ $element }) {
    return $element.closest('.form-group, .form-check')
  },
  errorClass: 'is-invalid',
  errorsWrapper: '<div class="invalid-feedback"></div>',
  errorTemplate: '<span></span>',
  successClass: 'is-valid',
  trigger: 'blur change ',
  triggerAfterFailure: 'input change',
})
