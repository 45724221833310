import { createRhomboid, RhomboidDefinition } from '../geometry/rhomboid'
import watchElements from '~scripts/utils/watchElements'
import { Breakpoint, currentBreakpoint } from './grid'
import debounce from 'lodash/debounce'

const rhomboid = createRhomboid()

const baseRadiuses: {
  [bp in Breakpoint]: { r0: number; r1: number; r2: number; r3: number }
} = {
  xs: { r0: 110 * 0.5, r1: 120 * 0.5, r2: 110 * 0.5, r3: 120 * 0.5 },
  sm: { r0: 110 * 0.7, r1: 120 * 0.7, r2: 110 * 0.7, r3: 120 * 0.7 },
  md: { r0: 110 * 0.8, r1: 120 * 0.8, r2: 110 * 0.8, r3: 120 * 0.8 },
  lg: { r0: 110 * 0.9, r1: 120 * 0.9, r2: 110 * 0.9, r3: 120 * 0.9 },
  xl: { r0: 110, r1: 120, r2: 110, r3: 120 },
}

const shapes: {
  [key in CurvedImageShape]: (
    width: number,
    height: number,
    bp: Breakpoint
  ) => RhomboidDefinition
} = {
  full: (width, height, bp) => ({
    width,
    height,
    rotated: false,
    ...baseRadiuses[bp],
  }),
  left: (width, height, bp) => ({
    width,
    height,
    rotated: false,
    cutLeft: true,
    ...baseRadiuses[bp],
  }),
  right: (width, height, bp) => ({
    width,
    height,
    rotated: false,
    cutRight: true,
    ...baseRadiuses[bp],
  }),
  'hero-right': (width, height, bp) => ({
    width,
    height,
    rotated: true,
    cutLeft: true,
    r0: baseRadiuses[bp].r0,
    r1: 0,
    r2: 0,
    r3: 0,
    angle: 3.35,
  }),
  'hero-left': (width, height, bp) => ({
    width,
    height,
    rotated: true,
    cutLeft: true,
    r0: 0,
    r1: baseRadiuses[bp].r1,
    r2: 0,
    r3: 0,
    angle: 3.35,
  }),
  'hero-title-left': (width, height, bp) => ({
    width,
    height,
    cutLeft: true,
    r0: ['xs', 'sm'].includes(bp) ? baseRadiuses[bp].r0 : 0,
    r1: baseRadiuses[bp].r1,
    r2: 0,
    r3: 0,
  }),
  'hero-title-right': (width, height, bp) => ({
    width,
    height,
    cutRight: true,
    r0: 0,
    r1: 0,
    r2: baseRadiuses[bp].r2,
    r3: ['xs', 'sm'].includes(bp) ? baseRadiuses[bp].r3 : 0,
  }),
  footer: (width, height, bp) => ({
    width,
    height,
    rotated: true,
    cutRight: true,
    r0: 0,
    r1: 0,
    r2: baseRadiuses[bp].r2,
    r3: 0,
    angle: 3.35,
  }),
}

const svgNS = 'http://www.w3.org/2000/svg'
const getOverlay = (
  el: Element
): {
  svgEl: SVGSVGElement
  pathEl: SVGPathElement
  setViewBox: (viewBox: [number, number, number, number]) => void
  setPath: (path: string) => void
} => {
  let svgEl = el.querySelector<SVGSVGElement>('svg.curved-image-overlay')
  if (!svgEl) {
    svgEl = document.createElementNS(svgNS, 'svg')
    svgEl.setAttribute('class', 'curved-image-overlay')
    el.appendChild(svgEl)
  }

  let pathEl = svgEl.querySelector<SVGPathElement>('path')
  if (!pathEl) {
    pathEl = document.createElementNS(svgNS, 'path')
    svgEl.appendChild(pathEl)
  }

  return {
    svgEl,
    pathEl,
    setViewBox: (viewBox: [number, number, number, number]) => {
      svgEl!.setAttribute('viewBox', viewBox.join(' '))
    },
    setPath: (path: string) => {
      pathEl!.setAttribute('d', path)
    },
  }
}

const render = (el: Element) => {
  const scale = parseFloat(el.getAttribute('data-scale') || '1') || 1
  const { setViewBox, setPath } = getOverlay(el)
  const shape = el.getAttribute('data-shape') || 'full'
  const { width, height } = el.getBoundingClientRect()
  setViewBox([0, 0, width, height])
  if (!(shape in shapes)) {
    setPath('')
  } else {
    const definition = shapes[shape as CurvedImageShape](
      width,
      height,
      currentBreakpoint()
    )
    const scaledDefinition: RhomboidDefinition =
      scale === 1
        ? definition
        : {
            ...definition,
            r0:
              typeof definition.r0 !== 'undefined'
                ? definition.r0 * scale
                : undefined,
            r1:
              typeof definition.r1 !== 'undefined'
                ? definition.r1 * scale
                : undefined,
            r2:
              typeof definition.r2 !== 'undefined'
                ? definition.r2 * scale
                : undefined,
            r3:
              typeof definition.r3 !== 'undefined'
                ? definition.r3 * scale
                : undefined,
          }

    let corners = rhomboid.getCorners(scaledDefinition)
    if (definition.rotated) {
      corners = [corners[2], corners[1], corners[0], corners[3]]
    }
    const paddingLeft = Math.max(
      corners[2].start[0],
      corners[2].end[0],
      corners[3].start[0],
      corners[3].end[0]
    )
    const paddingRight =
      width -
      Math.min(
        corners[0].start[0],
        corners[0].end[0],
        corners[1].start[0],
        corners[1].end[0]
      )
    const paddingTop = Math.max(
      corners[0].start[1],
      corners[0].end[1],
      corners[3].start[1],
      corners[3].end[1]
    )
    const paddingBottom =
      height -
      Math.min(
        corners[1].start[1],
        corners[1].end[1],
        corners[2].start[1],
        corners[2].end[1]
      )
    const below = Math.min(
      Math.max(corners[1].start[1], corners[1].end[1]),
      Math.max(corners[2].start[1], corners[2].end[1])
    )
    ;(el as HTMLDivElement).style.paddingLeft = paddingLeft + 'px'
    ;(el as HTMLDivElement).style.paddingRight = paddingRight + 'px'
    ;(el as HTMLDivElement).style.paddingTop = paddingTop + 'px'
    ;(el as HTMLDivElement).style.paddingBottom = paddingBottom + 'px'
    for (const innerEl of el.querySelectorAll<HTMLElement>('.curved-inner')) {
      if (innerEl.closest('.curved-overlay, .curved') !== el) {
        continue
      }
      innerEl.style.left = paddingLeft + 'px'
      innerEl.style.right = paddingRight + 'px'
      innerEl.style.top = paddingTop + 'px'
      innerEl.style.bottom = paddingBottom + 'px'
    }
    for (const innerEl of el.querySelectorAll<HTMLElement>(
      '.curved-below-shape'
    )) {
      if (innerEl.closest('.curved-overlay, .curved') !== el) {
        continue
      }
      innerEl.style.top = below + 'px'
    }
    if (el.classList.contains('hero-bg')) {
      const container = el.closest<HTMLElement>('.hero > .container-wide')
      if (container) {
        const factor = scaledDefinition.r0 ? 1.75 : 1
        container.style.paddingBottom = paddingBottom * factor + 'px'
      }
    }
    if (el.classList.contains('curved-overlay')) {
      setPath(rhomboid.getOverlayPath(scaledDefinition, 2))
    } else {
      setPath(rhomboid.getPath(scaledDefinition))
    }
  }
}
const destroy = (el: Element) => {
  const { svgEl } = getOverlay(el)
  el.removeChild(svgEl)
}

watchElements(
  '.curved-overlay, .curved',
  {
    attributeFilter: ['data-shape', 'data-scale'],
    watchResize: true,
  },
  {
    mount: (el: HTMLElement) => {
      render(el)
      const update = () => render(el)
      return {
        update: () => requestAnimationFrame(update),
        unmount: () => destroy(el),
      }
    },
  }
)
