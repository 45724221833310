import React, { useState, useEffect, useLayoutEffect } from 'preact/compat'

export type SelectPickerProps = {
  value: any
  onChange?: (value: any) => void
}
const SelectPicker: React.FunctionComponent<SelectPickerProps> = ({
  children,
  value,
  onChange,
  ...props
}) => {
  const [select, setSelect] = useState<HTMLSelectElement | null>(null)

  useLayoutEffect(() => {
    if (!select) {
      return
    }
    const _select = $(select).clone().insertAfter(select)
    _select.removeClass('d-none')
    $(_select).selectpicker()
    $(_select).selectpicker('val', value)
    const handleChange = () => {
      onChange && onChange($(_select).val())
    }
    $(_select).on('changed.bs.select', handleChange)
    return () => {
      $(_select).off('changed.bs.select', handleChange)
      $(_select).selectpicker('destroy')
      $(_select).remove()
    }
  })

  return (
    <select ref={setSelect} {...props} class="d-none">
      {children}
    </select>
  )
}

export default SelectPicker
