import React, { useCallback } from 'preact/compat'
import { MenuItem } from './menuDefinition'
import { useDispatch, useSelector } from './store/hooks'
import { UiSetLocationAction, UiBackAction } from './store/types/ui'

const MenuListItem: React.FunctionComponent<{ menuItem: MenuItem }> = ({
  menuItem,
}) => {
  const dispatch = useDispatch<UiSetLocationAction>()
  const openSubmenu = useCallback(
    (e: MouseEvent) => {
      e.preventDefault()
      if ((menuItem.children && menuItem.children.length) || menuItem.aside)
        dispatch({
          type: 'ui/set_location',
          location: menuItem,
        })
    },
    [dispatch, menuItem]
  )
  let active = false
  let loc = useSelector((state) => state.ui.location)
  while (loc && loc.parent) {
    active = active || loc === menuItem
    loc = loc.parent
  }
  if (menuItem.href && menuItem.href !== '#') {
    if ((menuItem.children && menuItem.children.length) || menuItem.aside) {
      return (
        <div
          class={[
            'nav-link-split',
            menuItem.className,
            active ? 'active' : '',
          ].join(' ')}
          id={menuItem.id}
        >
          <a class="nav-link" href={menuItem.href} target={menuItem.target}>
            <span>{menuItem.label}</span>
          </a>
          <a class="nav-link-split-arrow" onClick={openSubmenu}></a>
        </div>
      )
    }
    return (
      <a
        class={['nav-link', menuItem.className, active ? 'active' : ''].join(
          ' '
        )}
        id={menuItem.id}
        href={menuItem.href}
        target={menuItem.target}
      >
        <span>{menuItem.label}</span>
      </a>
    )
  }
  return (
    <a
      class={['nav-link', menuItem.className, active ? 'active' : ''].join(' ')}
      id={menuItem.id}
      href="#"
      onClick={openSubmenu}
    >
      <span>{menuItem.label}</span>
    </a>
  )
}

export type MenuListProps = {
  menuItem?: MenuItem
}
const MenuList: React.FunctionComponent<MenuListProps> = ({ menuItem }) => {
  const children = menuItem && menuItem.children ? menuItem.children : []
  const mobileMenu = useSelector((state) => state.ui.mobileMenu)
  const showBackButton =
    !!menuItem && !!menuItem.parent && (mobileMenu || !!menuItem.parent.parent)
  const dispatch = useDispatch<UiBackAction>()
  const goBack = useCallback(
    (e: MouseEvent) => {
      e.preventDefault()
      dispatch({ type: 'ui/back' })
    },
    [dispatch]
  )

  return (
    <nav class="nav nav-column-arrows">
      {showBackButton && (
        <>
          <a href="#" class="nav-link nav-link-back" onClick={goBack}>
            {menuItem!.label}
          </a>
          <hr />
        </>
      )}
      {children.map((child) => (
        <MenuListItem key={child.key} menuItem={child} />
      ))}
    </nav>
  )
}

export default MenuList
