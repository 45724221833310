import { UiResizeAction } from '../types/ui'
import { currentBreakpoint } from '~scripts/cssHelpers/grid'

const mainMenuDesktop = document.getElementById('mainmenu-desktop')

export const showMobileMenu = (): boolean => {
  if (!mainMenuDesktop) {
    return true
  }
  return mainMenuDesktop.scrollWidth > mainMenuDesktop.clientWidth
}

export const onResizeAction = (): UiResizeAction => ({
  type: 'ui/onresize',
  breakpoint: currentBreakpoint(),
  mobileMenu: showMobileMenu(),
})
