import React, { useCallback } from 'preact/compat'
import { VNode } from 'preact'
import useSizeObserver from '~scripts/utils/useSizeObserver'
import MotionDiv from '~scripts/components/MotionDiv'

type SlideProps = {
  title: VNode
  content: VNode
  setCurrent: (index: number) => void
  current: number
  index: number
}
const DesktopSlide: React.FunctionComponent<SlideProps> = ({
  title,
  content,
  setCurrent,
  current,
  index,
}) => {
  const active = index === Math.floor(current)
  const isPrevious = index < Math.floor(current)
  const titleClick = useCallback(() => setCurrent(index), [setCurrent, index])
  const [width, height, slideRef] = useSizeObserver()
  const [titleWidth, titleHeight, titleRef] = useSizeObserver()
  const progressbarPct = Math.min(1, Math.max(0, current - index))
  const progressbarHeight =
    active || isPrevious
      ? titleHeight + (height - titleHeight) * progressbarPct
      : 0

  return (
    <div class="slide slide-desktop mb-grid pr-5" ref={slideRef}>
      <div class="vertical-progressbar">
        <div class="bar" style={{ height: progressbarHeight + 'px' }}></div>
      </div>
      <MotionDiv
        className="slide-title"
        initial={{ color: '#000' }}
        onClick={titleClick} 
        ref={titleRef}
        animate={{
          color: active ? '#000' : '#D1D1D1',
        }}
        transition={{ type: 'tween' }}
      >
        {title}
      </MotionDiv>
      <MotionDiv
        className="slide-content"
        initial={{ height: 0 + 'px', opacity: 0 }}
        animate={{
          height: active ? 'auto' : 0 + 'px',
          opacity: active ? 1 : 0,
        }}
        transition={{ type: 'tween' }}
      >
        {content}
      </MotionDiv>
    </div>
  )
}

export default DesktopSlide
