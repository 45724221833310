import { Reducer } from 'redux'
import { DefinitionAction, DefinitionState } from '../types/definition'

const initialState: DefinitionState = {
  left: null,
  footerMenu: undefined,
  mainMenu: undefined,
}

const definitionReducer: Reducer<DefinitionState, DefinitionAction> = (
  state: DefinitionState = initialState,
  action
) => {
  switch (action.type) {
    case 'definition/set':
      return action.definition
    default:
      return state
  }
}

export default definitionReducer
