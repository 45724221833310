import 'core-js/es/weak-set'
import 'core-js/es/symbol'
import 'core-js/es/promise'
import 'core-js/features/array/from'
import 'core-js/features/array/includes'
import 'core-js/features/array/find'
import 'core-js/features/object/assign'
import 'element-closest-polyfill'

import './parcel_fixes'
import './modules/sideButtons'
import $ from './jquery-global'
import 'popper.js'
import 'bootstrap'
import 'bootstrap-select'
import './parsley'
import './navbar'
//import './header'

//import './navi'
import './navi-neu'
import './checkboxfilter'
import './validate'
import './filter'
import './ajaxfilter'
import 'cssHelpers/*.ts'

import 'mmenu-js/src/mmenu';
import 'mmenu-js/dist/mmenu.polyfills';

import '../../node_modules/jquery-match-height'
import '../../node_modules/aos/dist/aos.js'
import '../../node_modules/slick/index.js'
import '../../node_modules/slick-carousel/slick/slick.js'


import './modules/worldMap'
import scrollbarSlider from './modules/scrollbarSlider'
import accordionSlider from './modules/accordionSlider.ts'
import slider from './modules/slider'
import './modules/video'
import './modules/tabs'
import './modules/calculator.js'
import './vendor/jquery.fancybox.js'
import Swiper from 'swiper'
import AOS from 'aos';


AOS.init({
  // Global settings:
  disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
  startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
  initClassName: 'aos-init', // class applied after initialization
  animatedClassName: 'aos-animate', // class applied on animation
  useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
  disableMutationObserver: false, // disables automatic mutations' detections (advanced)
  debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
  throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
  

  // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
  offset: 120, // offset (in px) from the original trigger point
  delay: 200, // values from 0 to 3000, with step 50ms
  duration: 1000, // values from 0 to 3000, with step 50ms
  easing: 'ease', // default easing for AOS animations
  once: false, // whether animation should happen only once - while scrolling down
  mirror: false, // whether elements should animate out while scrolling past them
  anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation

});

Array.from(document.querySelectorAll('.accordion-slider')).forEach(
  accordionSlider
)

// ##############################################
	// #################### forms ###################
	// ##############################################  

  $('input, textarea').each(function(){
    if ($(this).val() != "") {
      $(this).addClass("has-content");
    } else {
      $(this).removeClass("has-content");
    }
  });
  
  $('input, textarea').focusin(function(){
    $(this).addClass("focus");
  });

  $('input, textarea').focusout(function(){
    $(this).removeClass("focus");
    if ($(this).val() != "") {
      $(this).addClass("has-content");
    } else {
      $(this).removeClass("has-content");
    }
  });

$( document ).ready(function() {
  $("#select option[value=0]").attr('selected', 'selected');
  
  

  $(".auswahlendkunde option").click(function(){

    var $class1 = $(this).html();
    $("body").attr('id', $class1);
    
  });

  
  

  $(".no-results").hide();
  $(".reset-filters").hide();

  $("select.w-filter-by").change(function() {
    let filters = $.map($("select.w-filter-by").toArray(), function(e) {
      return $(e).val();
    }).join(".");
    $(".w-results").find("li").hide();
    let results = $(".w-results").find("li." + filters);
    console.log(filters);
    if (results.length){
      results.show();
      $(".no-results").hide();
      $(".reset-filters").show();
    } else {
      $(".no-results").show();
      $(".reset-filters").show();
    }
  });
  // Filter reset
  $('.reset-filters').click(function(e) {
    $(".w-results").find("li").show();
  });	
});




  //var $navOffset = $('body').data('offset');
  
	if ($(window).width() < 992) {
		var $navOffset = 100;
	} else {
    var $navOffset = 130;
	} 

 /* var content = document.getElementById("techdatas");
var button = document.getElementById("show-more");

button.onclick = function () {

	if(content.className == "open my-3 columns"){
    $('#show-more').addClass('active');
		//shrink the box
		content.className = "my-3 columns";
    $('#show-more').removeClass('active');
	} else {
		//expand the box
		content.className = "open my-3 columns";
    $('#show-more').addClass('active');
	}

};
*/

$('body').on('click', '*[data-href]', function (this: HTMLElement, e) {
  e.preventDefault()
  const a = document.createElement('a')
  a.href = $(this).data('href')
  if ($(this).data('target') === '_blank') {
    window.open(a.href, '_blank')
  } else {
    location.href = a.href
  }
})



$(window).on("load resize scroll", function() {
  $(".bg-relative").each(function() {
    var windowTop = $(window).scrollTop() + 400;
    var elementTop = $(this).offset().top;
    var leftPosition = windowTop - elementTop;
      $(this)
        .find(".bg-move")
        .css({ left: leftPosition });
  });
});

(function($) {

  $.fn.visible = function(partial, hidden) {

    var $t = $(this).eq(0),
      t = $t.get(0),
      $w = $(window),
      viewTop = $w.scrollTop(),
      viewBottom = viewTop + $w.height(),
      _top = $t.offset().top,
      _bottom = _top + $t.height(),
      compareTop = partial === true ? _bottom : _top,
      compareBottom = partial === true ? _top : _bottom,
      clientSize = hidden === true ? t.offsetWidth * t.offsetHeight : true;

    return !!clientSize && ((compareBottom <= viewBottom) && (compareTop >= viewTop));
  };

})(jQuery);


// Scrolling Functions
$(window).scroll(function(event) {
  function padNum(num) {
    if (num < 10) {
      return "" + num;
    }
    return num;
  }

  var first = parseInt($('.c1').text());
  var second = parseInt($('.c2').text());
  var third = parseInt($('.c3').text());

  function countStuffUp(points, selector, duration) { //Animate count
    $({
      countNumber: $(selector).text()
    }).animate({
      countNumber: points
    }, {
      duration: duration,
      easing: 'linear',
      step: function() {
        $(selector).text(padNum(parseInt(this.countNumber)));
      },
      complete: function() {
        $(selector).text(points);
      }
    });
  }

  // Output to first-count
  $(".first-count").each(function(i, el) {
    var el = $(el);
    if (el.visible(true)) {
      countStuffUp(first, '.first-count', 1600);
    }
  });

  // Output to second count
  $(".second-count").each(function(i, el) {
    var el = $(el);
    if (el.visible(true)) {
      countStuffUp(second, '.second-count', 2000);
    }
  });
  // Output to second count
  $(".third-count").each(function(i, el) {
    var el = $(el);
    if (el.visible(true)) {
      countStuffUp(third, '.third-count', 2500);
    }
  });

});
$(document).ready(function() {
 
  setTimeout(function(){
      $('body').addClass('loaded');
      $('#tmt-menu').removeClass('d-none');
      $('#tmt-menu-desktop').removeClass('d-none');
  }, 2000);

  $('button#tmt-menu-button').click(()=>{
    $('.mm-panels').addClass('container')
})

});



$(document).ready(function(){

  

$('.branchen-slider').slick({
  slidesToShow: 1,
  arrow: true,
  centerMode: false,
  prevArrow: '<span class="slick-prev"><i class="fa-light fa-angle-left"></i></span>',
  nextArrow: '<span class="slick-next"><i class="fa-light fa-angle-right"></i></span>',
  responsive: [{
      breakpoint: 992,
      settings: {
          slidesToShow: 1,
      }
  }]
});

    
});




$(document).ready(function(){
  $('input[type="checkbox"]').click(function(){
    var val = $(this).attr("value");
    $(".msg").removeClass("active");
    $("." + val).addClass("active");
  });
});


// Slider (Fullscreen)
$('.slider-fullscreen').each(function () {
  var slider = $(this);
  slider.slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    infinite: true,
    autoplay: true, 
    autoplaySpeed: 5000,
    pauseOnHover: false,
    speed: 1200,
    fade: true,
    prevArrow: '<span class="slick-prev"><i class="fa-light fa-angle-left"></i></span>',
    nextArrow: '<span class="slick-next"><i class="fa-light fa-angle-right"></i></span>',
  });
});  

$(".slider-fullscreen-scroll a").click(function(event) {
  event.preventDefault();
  var parentSection = $(this).closest('section');
  var nextSection = parentSection.next('section');
  if ($(nextSection).length) {
    if ($(nextSection).offset()) {
      $('html,body').animate( { scrollTop:($(nextSection).offset().top - ($navOffset - 1))} , 800);
    }
  }
});	


// Slider (Karte)
$('.slider-map-nav').each(function () {
  var slider = $(this);
  slider.slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: false,
    speed:500,
    focusOnSelect: true,
    prevArrow: '<span class="slick-prev"><i class="fa-light fa-angle-left"></i></span>',
    nextArrow: '<span class="slick-next"><i class="fa-light fa-angle-right"></i></span>',
    asNavFor: '.slider-map',
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          speed:500,
          focusOnSelect: true,
          asNavFor: '.slider-map',
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          speed:500,
          focusOnSelect: true,
          asNavFor: '.slider-map',
        }
      }
    ]
  });
  slider.on('setPosition', function(e) {
    var slidesShown = $(slider).slick('slickGetOption', 'slidesToShow');
    var numberOfSlides = $(slider).find('.slick-slide').length;
    //console.log('slidesShown: '+slidesShown);
    //console.log('numberOfSlides: '+numberOfSlides);
    if (slidesShown === numberOfSlides) {
      $(slider).find('.slick-track').css('transform', 'translate3d(0px, 0px, 0px)');
    }
  });
}); 
$('.slider-map-image').each(function () {
  var slider = $(this);
  slider.slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    infinite: false,
    fade: true,
    adaptiveHeight: false,
    speed: 500,
    //customPaging: function(slider, i) { 
    //	var title = $(slider.$slides[i]).find('.title').text();
    //	return '<div class="content">'+title+'</div>';
    //},
    //appendDots: slider.parent().find('.slider-map-dots'),
    asNavFor: '.slider-map',
  });
});  
$('.slider-map-text').each(function () {
  var slider = $(this);
  slider.slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    infinite: false,
    fade: true,
    adaptiveHeight: true,
    speed: 500,
    asNavFor: '.slider-map',
  });
}); 

// Slider (Reasons)
$('.slider-reasons').each(function () {
  var slider = $(this);
  slider.slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    infinite: true,
    swipe: true,
    //autoplay: true, 
    //autoplaySpeed: 6500,
    pauseOnHover: false,
    speed: 1600,
    fade: true,
    appendDots: $('.containerdots'),
    prevArrow: $('.reason-table-body .slick-prev'),
    nextArrow: $('.reason-table-body .slick-next')
  });
});  

$('.center').slick({
  centerMode: true,
  centerPadding: '10px',
  infinite: true,
  autoplay: true, 
  autoplaySpeed: 5000,
  pauseOnHover: false,
  speed: 1200,
  arrows: true,
  dots: false,
  slidesToShow: 1.66,
  prevArrow:"<button class='slick-prev slick-arrow' aria-label='Previous' type='button'><i class='far fa-chevron-left' style='font-size:18px;color:#fff;'></i></button>",
    nextArrow:"<button class='slick-next slick-arrow' aria-label='Next' type='button'><i class='far fa-chevron-right' style='font-size:18px;color:#fff;'></i></button>",
  responsive: [
    {
      breakpoint: 768,
      settings: {
        arrows: true,
        centerMode: true,
        centerPadding: '10px',
        slidesToShow: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        arrows: true,
        centerMode: true,
        centerPadding: '10px',
        slidesToShow: 1
      }
    }
  ]
});

$('.centerimage').slick({
  centerMode: true,
  centerPadding: '60px',
  infinite: true,
  autoplay: false, 
  autoplaySpeed: 5000,
  pauseOnHover: false,
  speed: 1200,
  arrows: false,
  dots: true,
  slidesToShow: 1.66,
  prevArrow:"<button class='slick-prev slick-arrow' aria-label='Previous' type='button'><i class='far fa-chevron-left' style='font-size:18px;color:#fff;'></i></button>",
    nextArrow:"<button class='slick-next slick-arrow' aria-label='Next' type='button'><i class='far fa-chevron-right' style='font-size:18px;color:#fff;'></i></button>",
  responsive: [
    {
      breakpoint: 768,
      settings: {
        arrows: false,
        centerMode: true,
        centerPadding: '10px',
        slidesToShow: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        arrows: false,
        centerMode: true,
        centerPadding: '10px',
        slidesToShow: 1
      }
    }
  ]
});



// Slider History 
$('.slider-history-content').each(function () {
  var slider = $(this);
  var progress = slider.parent().find('.progress-bar .progress');
  slider.on('init reInit afterChange', function(event, slick, currentSlide, nextSlide){
    //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
    var i = (currentSlide ? currentSlide : 0) + 1;
    // Progress-Bar
    var oneSlideProgressWidth = 100/slick.slideCount;
    progress.css('width', (i*oneSlideProgressWidth)+'%');
  });
  slider.slick({
    arrows: true,
    dots: false,
    infinite: false,
    swipe: true,
    fade: true,
    slidesToShow: 1,
    verticalSwiping: true,
    slidesToScroll: 1,
    adaptiveHeight: true,
    asNavFor: '.slider-history-nav',
    prevArrow:"<span class='slick-prev'><i class='fa-light fa-angle-left text-primary'></i></span>",
    nextArrow:"<span class='slick-next'><i class='fa-light fa-angle-right text-primary'></i></span>",    
    appendArrows: slider.parent().find('.slider-history-arrows')
  });
  slider.on('setPosition', function(e) {
    //console.log('SetPosition');
    var slidesShown = $(slider).slick('slickGetOption', 'slidesToShow');
    var numberOfSlides = $(slider).find('.slick-slide').length;
    //console.log('slidesShown: '+slidesShown);
    //console.log('numberOfSlides: '+numberOfSlides);
    if (slidesShown === numberOfSlides || slidesShown > numberOfSlides) {
      progress.css('width', (100/numberOfSlides)+'%');
    }
  });
});  
// Slider History Nav
$('.slider-history-nav').each(function () {
  var slider = $(this);
  slider.slick({
    arrows: false,
    dots: false,
    infinite: false,
    centerMode: false,
    verticalSwiping: true,
    slidesToShow: 6,
    swipe: true,
    slidesToScroll: 1,
    adaptiveHeight: false,
    focusOnSelect: true,
    asNavFor: '.slider-history-content',
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          arrows: false,
          swipe: true,
          dots: false,
          infinite: false,
          slidesToShow: 3,
          centerMode: true,
          slidesToScroll: 1,
          adaptiveHeight: false,
          focusOnSelect: true,
          asNavFor: '.slider-history-content',   
        }
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          swipe: true,
          dots: false,
          infinite: false,
          slidesToShow: 1,
          centerMode: true,
          slidesToScroll: 1,
          adaptiveHeight: false,
          focusOnSelect: true,
          asNavFor: '.slider-history-content',
        }
      }
    ]
  });
  slider.on('setPosition', function(e) {
    //console.log('SetPosition');
    var slidesShown = $(slider).slick('slickGetOption', 'slidesToShow');
    var numberOfSlides = $(slider).find('.slick-slide').length;
    //console.log('slidesShown: '+slidesShown);
    //console.log('numberOfSlides: '+numberOfSlides);
    if (slidesShown === numberOfSlides || slidesShown > numberOfSlides) {
      $(slider).find('.slick-track').css('transform', 'translate3d(0px, 0px, 0px)');
    }
  });
});  

