import React from 'preact/compat'

let AnimatePresenceOriginal = React.forwardRef<HTMLDivElement>((props, ref) => (
  <React.Fragment {...props} ref={ref} />
))

if (window.Proxy) {
  /*import('../../../../../node_modules/framer-motion').then((x) => {
    AnimatePresenceOriginal = x.AnimatePresence
  })*/
  import('../../../node_modules/framer-motion').then((x) => {
    AnimatePresenceOriginal = x.AnimatePresence
  })
}

const AnimatePresence = React.forwardRef<HTMLDivElement>((props, ref) => (
  <AnimatePresenceOriginal {...props} ref={ref} />
))

export default AnimatePresence
