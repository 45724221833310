import React from 'preact/compat'
import useSizeObserver from '../../../utils/useSizeObserver'
import MotionDiv from '~scripts/components/MotionDiv'
import AnimatePresence from '~scripts/components/AnimatePresence'

const Crossfade: React.FunctionComponent<{
  currentKey: string
}> = ({ currentKey, children }) => {
  const [contentWidth, contentHeight, contentRef] = useSizeObserver()
  const height = contentHeight

  return (
    <div class="crossfade" style={{ height }}>
      <AnimatePresence>
        <MotionDiv
          key={currentKey}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ type: 'tween' }}
          exit={{ opacity: 0 }}
        >
          <div ref={contentRef}>{children}</div>
        </MotionDiv>
      </AnimatePresence>
    </div>
  )
}

export default Crossfade
