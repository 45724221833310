interface Rhomboid {
  getCorners: (args: RhomboidDefinition) => [Corner, Corner, Corner, Corner]
  getOverlayPath: (args: RhomboidDefinition, offset: number) => string
  getPath: (args: RhomboidDefinition) => string
}

export type RhomboidDefinition = {
  cutLeft?: boolean
  cutRight?: boolean
  corners?: number
  rotated?: boolean
  r0?: number
  r1?: number
  r2?: number
  r3?: number
  width: number
  height: number
  angle?: number
}

type Point = [number, number]
const swap = ([a, b]: Point): Point => [b, a]

export class Corner {
  start: Point = [0, 0]
  end: Point = [0, 0]
  r: number = 0
  dir: 0 | 1 = 1

  getPathFragment = (start: 'M' | 'L' = 'L') => {
    if (this.r === 0) {
      return `${start}${this.start.join(' ')}`
    }
    return (
      `${start}${this.start.join(' ')}` +
      `A${this.r} ${this.r} 0 0 ${this.dir} ${this.end.join(' ')}`
    )
  }

  invert() {
    this.start = swap(this.start)
    this.end = swap(this.end)
    this.dir = this.dir ? 0 : 1
  }
}

const cornerFromPoint = (p: Point): Corner => {
  const result = new Corner()
  result.start = p
  result.end = p
  return result
}

/**
 *
 *      3       0
 *     /-------/
 *    /       /
 *   /-------/
 *  2       1
 *
 * Rotated:
 *
 *
 *  3      2
 *  __---"""
 *  |      |
 *  |      |
 *  __---"""
 *  0      1
 */

const cache: { [angle: number]: { cos: number; sin: number; tan: number } } = {}
const getCosSinTan = (
  angle: number
): { cos: number; sin: number; tan: number } => {
  if (!cache[angle]) {
    cache[angle] = {
      cos: Math.cos((angle / 180) * Math.PI),
      sin: Math.sin((angle / 180) * Math.PI),
      tan: Math.tan((angle / 180) * Math.PI),
    }
  }
  return cache[angle]
}
export const createRhomboid = (): Rhomboid => {
  const getCorners = ({
    rotated = false,
    r0 = 110,
    r1 = 120,
    r2 = 110,
    r3 = 120,
    cutLeft = false,
    cutRight = false,
    width,
    height,
    angle = 11,
  }: RhomboidDefinition): [Corner, Corner, Corner, Corner] => {
    const { cos, sin, tan } = getCosSinTan(angle)
    const [w, h] = rotated ? [height, width] : [width, height]

    const result: [Corner, Corner, Corner, Corner] = [
      new Corner(),
      new Corner(),
      new Corner(),
      new Corner(),
    ]

    // right border
    if (cutRight) {
      result[0].start = result[0].end = [w, 0]
      result[1].start = result[1].end = [w, h]
    } else {
      const p0: Point = [w - r0, 0]
      const p1: Point = [p0[0] + cos * r0, p0[1] + r0 + sin * r0]

      const lineEndY = h - r1 + sin * r1
      const lineHeight = lineEndY - p1[1]
      const lineEndX = p1[0] - tan * lineHeight
      const p2: Point = [lineEndX, lineEndY]
      const p3: Point = [p2[0] - cos * r1, h]
      result[0].start = p0
      result[0].end = p1
      result[0].r = r0
      result[1].start = p2
      result[1].end = p3
      result[1].r = r1
    }

    // left border
    if (cutLeft) {
      result[2].start = result[2].end = [0, h]
      result[3].start = result[3].end = [0, 0]
    } else {
      const p0: Point = [r2, h]
      const p1: Point = [r2 - cos * r2, h - r2 - sin * r2]

      const lineEndY = r3 - sin * r3
      const lineHeight = p1[1] - lineEndY
      const lineEndX = p1[0] + tan * lineHeight
      const p2: Point = [lineEndX, lineEndY]
      const p3: Point = [p2[0] + cos * r3, 0]
      result[2].start = p0
      result[2].end = p1
      result[2].r = r2
      result[3].start = p2
      result[3].end = p3
      result[3].r = r3
    }

    if (rotated) {
      result.forEach((corner) => corner.invert())
    }

    return result
  }

  const getOverlayPath = (def: RhomboidDefinition, o: number = 1): string => {
    let result = ''
    const [width, height] = def.rotated
      ? [def.height, def.width]
      : [def.width, def.height]
    const [c0, c1, c2, c3] = getCorners({
      ...def,
      width,
      height,
      rotated: false,
    })

    if (!def.cutRight) {
      const corners: Corner[] = [
        c0,
        c1,
        cornerFromPoint([c1.end[0], height + o]),
        cornerFromPoint([width + o, height + o]),
        cornerFromPoint([width + o, -o]),
        cornerFromPoint([c0.start[0], -o]),
      ]
      if (def.rotated) {
        corners.forEach((c) => c.invert())
      }
      result +=
        corners.map((c, i) => c.getPathFragment(i ? 'L' : 'M')).join('') + 'Z'
    }

    if (!def.cutLeft) {
      const corners: Corner[] = [
        c2,
        c3,
        cornerFromPoint([c3.end[0], -o]),
        cornerFromPoint([-o, -o]),
        cornerFromPoint([-o, height + o]),
        cornerFromPoint([c3.start[0], height + o]),
      ]
      if (def.rotated) {
        corners.forEach((c) => c.invert())
      }
      result +=
        corners.map((c, i) => c.getPathFragment(i ? 'L' : 'M')).join('') + 'Z'
    }

    return result
  }

  const getPath = (def: RhomboidDefinition): string => {
    const corners = getCorners(def)
    return (
      corners.map((c, i) => c.getPathFragment(i ? 'L' : 'M')).join('') + 'Z'
    )
  }

  return {
    getCorners,
    getOverlayPath,
    getPath,
  }
}
