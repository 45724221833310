// Header Functions

document.addEventListener(
    "DOMContentLoaded", () => {
      var $menuDesktop = $("#tmt-menu").clone();
      $menuDesktop.attr( "id", "tmt-menu-desktop" );
      $('#tmt-menu').after($menuDesktop);
      const menuDesktop = new Mmenu( '#tmt-menu-desktop', {
        // options
        "extensions": [
          "position-front",
          "position-top",
          "multiline"
        ],
        "navbar": false,
        "setSelected": {
          //"hover": true,
          "parent": true
        },
        "navbars": [
          {
            "position": "top",
            "content": [
              '<a class="navbar-brand mr-auto" href="#"><img src="/typo3conf/ext/hg_KOCHS/Resources/Public/img/fho-logo-white.png" alt="Fenster Hoffmann" class="logo" width="250"><img src="/typo3conf/ext/hg_KOCHS/Resources/Public/img/fho-logo-white.png" alt="Fenster Hoffmann" class="logo-ie"></a>',      
              'close',
          ]
          },
          {
            "position": "bottom",
            "content": [
              '<div class="nav-lang mr-auto bg-primary"><a class="btn btn-outline-primary mr-3 mb-2" href="/anfrage"><i class="fal fa-list-alt"></i> Anfrage</a><a class="btn btn-outline-primary mr-3 mb-2" href="/kontakt"><i class="fal fa-address-card"></i> Kontakt</a><a class="btn btn-outline-primary mr-3 mb-2" href="/unternehmen/downloads"><i class="fal fa-newspaper"></i> Downloads</a><a class="btn btn-outline-primary mr-3 mb-2" href="https://fenster-hoffmann.traumtuer-konfigurator.de/" target="_blank"><i class="fal fa-sliders-v-square"></i> Konfigurator</a><a class="btn btn-outline-primary mb-2" href="/service/kundendienst/"><i class="fal fa-users-cog"></i> Kundendienst</a></div></div>',
              '<a class="navbar-brand " href="/impressum">Impressum</a>',    
              '<a class="navbar-brand mr-auto" href="/datenschutz">Datenschutz</a>',         
              '<div><a href="https://www.facebook.com/fenster.hoffmann/" class="icon socicon socicon-linkedin  text-white pr-3" target="_blank" alt="Facebook" aria-label="Facebook"><i class="fab fa-facebook"></i></a><a href="https://www.instagram.com/fensterhoffmann/" class="icon socicon socicon-linkedin  text-white pr-3" target="_blank" alt="Instagram" aria-label="Instagram"><i class="fab fa-instagram"></i></a></div>',
          ]
          },
        ],
        "columns": {
          "add": true,
          "visible": { "min": 1, "max": 5 }
        },
        "hooks": {
          "openPanel:start": function( $panel ) {
            //console.log($panel);
          }
        }
      }, {
          // configuration
      });
      const menuMobile = new Mmenu( "#tmt-menu", {
          // options
          "extensions": [
            "position-front",
            "position-right",
            "multiline"
          ],
          "navbars": [
            {
              "position": "top",
              "content": [
                //$("#nav-lang").html(),
                //'#nav-lang',
                //'<div class="nav-lang"></div>',
                //'<div class="nav-lang"><ul class="nav"><li class="nav-item"><a href="#" class="nav-link">DE</a></li><li class="nav-item"><a href="#" class="nav-link">EN</a></li></ul></div>',
                '<a class="navbar-brand mr-auto pl-3" href="#"><img src="/typo3conf/ext/hg_KOCHS/Resources/Public/img/fho-logo-white.png" alt="VRI" class="logo" width="200"><img src="/typo3conf/ext/hg_KOCHS/Resources/Public/img/fho-logo-white.png" alt="VRI" class="logo-ie"></a>',            
                'close'
              ]
            },
            {
              "position": "top",
              "content": [
                'prev',
                'title',
                //$("#nav-lang").html(),
                //'#nav-lang',
                //'<div class="nav-lang"></div>',
                //'<div class="nav-lang"><ul class="nav"><li class="nav-item"><a href="#" class="nav-link">DE</a></li><li class="nav-item"><a href="#" class="nav-link">EN</a></li></ul></div>',
              ]
            },
            /*{
              "position": "bottom",
              "class" : "py-0",
              "content": [
                '<a class="btn btn-outline-primary mr-3 mb-2" href="#"><i class="fal fa-message"></i> Anfrage</a><br>',
              ]
            },
            {
              "position": "bottom",
              "content": [
                '<a class="btn btn-outline-primary mr-3 mb-2" href="#"><i class="fal fa-envelope"></i> Kontakt</a>',
              ]
            },
            {
              "position": "bottom",
              "content": [
                '<a class="btn btn-outline-primary mr-3 mb-2" href="#"><i class="fal fa-newspaper"></i> Infomaterial</a>',
              ]
            },
            {
              "position": "bottom",
              "content": [
                '<a class="btn btn-outline-primary" href="#"><i class="fal fa-filters"></i> Konfigurator</a>',
              ]
            },*/
            {
              "position": "bottom",
              "content": [
                '<a class="btn btn-outline-primary mr-3 mb-2" href="/anfrage"><i class="fal fa-list-alt"></i> Anfrage</a><br>',
                '<a class="btn btn-outline-primary mr-3 mb-2" href="/kontakt"><i class="fal fa-address-card"></i> Kontakt</a><br>',
              ]
            },
            {
              "position": "bottom",
              "content": [
                '<a class="btn btn-outline-primary mr-3 mb-2" href="/unternehmen/downloads"><i class="fal fa-newspaper"></i> Downloads</a><br>',
                '<a class="btn btn-outline-primary" ref="https://fenster-hoffmann.traumtuer-konfigurator.de/" target="_blank><i class="fal fa-sliders-v-square"></i> Konfigurator</a><br>',
              ]
            },
            {
              "position": "bottom",
              "content": [
                '<a class="btn btn-outline-primary mr-3 mb-2" href="/service/kundendienst/"><i class="fal fa-users-cog"></i> Kundendienst</a><br>',
              ]
            },
            {
              "position": "bottom",
              "content": [
                '<a class="navbar-brand " href="/impressum">Impressum</a>',    
                '<a class="navbar-brand mr-auto" href="/datenschutz">Datenschutz</a>',
              ]
            },
            /*{
              "position": "bottom",
              "content": [
                '<div><a href="https://facebook.com" class="icon socicon socicon-linkedin  text-white pr-3" target="_blank" alt="Facebook" aria-label="Facebook"><i class="fab fa-facebook"></i></a><a href="" class="icon socicon socicon-linkedin  text-white pr-3" target="_blank" alt="Instagram" aria-label="Instagram"><i class="fab fa-instagram"></i></a><a href="" class="icon socicon socicon-linkedin  text-white pr-3" target="_blank" alt="Linkedin" aria-label="Linkedin"><i class="fab fa-linkedin-in"></i></a><a href="" class="icon socicon socicon-xing   text-white pr-3" target="_blank" alt="Xing" aria-label="Xing"><i class="fab fa-xing"></i></a><a href="" class="icon socicon socicon-xing   text-white" target="_blank" alt="Xing" aria-label="Xing"><i class="fab fa-youtube"></i></a></div>',
              ]
            },*/
          ],
          "hooks": {
            "openPanel:start": function( $panel ) {
              //console.log($panel.id);
              //console.log($panel.parentElement.firstChild.id);
              if ($panel.id != $panel.parentElement.firstChild.id) {
                //console.log('Ist NICHT das erste Panel');
                $('#tmt-menu .mm-navbars_top').addClass('mm-navbar-sub');
                //$('#tmt-menu .mm-navbars_bottom').addClass('mm-hidden');
                $('#tmt-menu .mm-navbar__title ').removeClass('mm-hidden');
              } else {
                //console.log('Ist das erste Panel');
                $('#tmt-menu .mm-navbars_top').removeClass('mm-navbar-sub');
                $('#tmt-menu .mm-navbars_bottom').removeClass('mm-hidden');
                $('#tmt-menu .mm-navbar__title ').addClass('mm-hidden');
              }
            }
         }
        }, {
            // configuration
        });
        const apiMenuDesktop = menuDesktop.API;
        const apiMenuMobile = menuMobile.API;
        document.querySelector( "#tmt-menu-button" )
          .addEventListener(
              "click", ( evnt ) => {
                  evnt.preventDefault();
                  if ($(window).width() > 991) {
                    apiMenuDesktop.open();
                  } else {
                    apiMenuMobile.open();
                  }
                  
              }
          );
        /*$(window).on("resize", function() {
          if ($(window).width() < 992) {
            apiMenuDesktop.close();
          } else {
            apiMenuMobile.close();
          }
        });*/
    }
  );
  
  
  $(document).ready(function(){
  
      // Fix für Präsi
    //$( "#tmt-menu-button" ).on('click', function(e) {
    //  e.preventDefault();
    //  alert('Das Menü folgt – bitte über das Logo im Header zur Übersicht navigieren.');
    //});
  
    // ##############################################
    // #################### Nav #####################
    // ##############################################
    
  
    // Sticky-Header und responsive Nav
      if ($(window).width() > 767) {
          var $offsetStickyNav = 110; // $navbar-height-md: 110px;
    } else {
      var $offsetStickyNav = 80; // $navbar-height-xs: 80px;
    }
        
      /*$(window).resize(function() {
      if ($(window).width() > 767) {
        $offsetStickyNav = 110; 
      } else {
        $offsetStickyNav = 80; 
      }
      });*/
  
    //$('.menu-btn').click(function(){
    //  //$(this).toggleClass('collapsed');
    //  $('body').toggleClass('nav-open');
    //  setTimeout("$('.nav-overlay-inner').scrollTop(0);", 600);
    //});
  //
    //$('.nav-overlay .menu-btn-close').click(function(){
    //  $('body').toggleClass('nav-open');
    //  setTimeout("$('.nav-overlay-inner').scrollTop(0);", 600);
    //});
  //
    //$('.menu-panel .nav-btn').click(function(e){
    //  e.preventDefault;
    //  //console.log(this);
    //  $(this.hash).siblings().addClass('menu-hidden');
    //  $(this.hash).siblings().removeClass('menu-hidden');
    //  $(this.hash).removeClass('menu-hidden');
    //  $(this.hash).addClass('menu-panel_opened');
    //});
  //
  //
    
    
    
    // Hide Header on on scroll down
    var didScroll;
    var lastScrollTop = 0;
    var delta = 5;
    var navbarHeight = $('header').outerHeight();  
  
    $(window).scroll(function(event){
        didScroll = true;      
    });
  
    setInterval(function() {
        if (didScroll) {
            hasScrolled();
            hashVisibilty();
            didScroll = false;
        }
    }, 250);
  
    function hashVisibilty() {
        if ($(window).scrollTop() > 500) {
        $('#container .tmt-bg').fadeOut(600,'swing');
      } else {
        $('#container .tmt-bg').fadeIn(600,'swing');
      }
    }
  
    /*function hasScrolled() {
      
      var st = $(window).scrollTop();
    
      if (Math.abs(lastScrollTop - st) <= delta) 
        return;
          
      if (st > lastScrollTop && st > navbarHeight){
        $('header .header-lp a').removeClass('d-block').addClass('d-none');
        $('header').addClass('bg-transparent');
      } else {
        if (st + $(window).height() < $(document).height()) {
          $('header .header-lp a').removeClass('d-none').addClass('');
          $('header').removeClass('bg-transparent');
        }
        if (st < 10) {
          $('header .header-lp a').removeClass('d-block');
          $('header').removeClass('bg-transparent');
        } 
      }
      lastScrollTop = st;
    }*/
    
    hasScrolled();
    hashVisibilty();
  
  
    // ##############################################
      // #################### Map #####################
      // ##############################################
  
      $('.map-collapse').on('shown.bs.collapse', function () {
      var parentItem = $(this).closest('section');
      var win = $(window);
      var viewport = {
        top : win.scrollTop(),
      };
      var bounds = parentItem.offset();
      var itemHeight = parentItem.outerHeight();
      if (itemHeight > win.height() || (viewport.top+(win.height()/2)) < bounds.top || bounds.top < viewport.top) {
        var $navOffset = $offsetStickyNav;
        $('html, body').animate({
          scrollTop: ($(parentItem).offset().top - $navOffset)
        }, 800);
      }
    });
  
    // ##############################################
      // ################# Accordion ##################
      // ##############################################
  
    $('.accordion .collapse, .product-tooltips .collapse').on('shown.bs.collapse', function (e) {
      if ($(this).hasClass('modal-body')) {
        var id = $(this)[0].id;
        var parentItem = $("#" + id).closest('.product-modal');
      } else {
        var id = $(e.target).parent().find("[id]")[0].id;
        var cardHeader = $("#" + id);
        var parentItem = cardHeader.closest('.card');  
      }
      var win = $(window);
      var viewport = {
        top : win.scrollTop(),
      };
      var bounds = parentItem.offset();
      var itemHeight = parentItem.outerHeight();
      if (itemHeight > win.height() || (viewport.top+(win.height()/2)) < bounds.top || bounds.top < viewport.top) {
        var $navOffset = $offsetStickyNav;
        $('html, body').animate({
          scrollTop: ($(parentItem).offset().top - $navOffset)
        }, 800);
      }
    });
  
  
  });

