import React from 'preact/compat'
import { HTMLMotionProps } from 'framer-motion'

let MotionDivOriginal = React.forwardRef<
  HTMLDivElement,
  HTMLMotionProps<'div'>
>(({ initial, animate, exit, transition, ...props }, ref) => (
  <div
    {...props}
    ref={ref}
    style={{ ...props.style, ...initial, ...animate }}
  />
))

if (window.Proxy) {
  /*import('../../../../../node_modules/framer-motion').then((x) => {
    MotionDivOriginal = x.motion.div
  })*/
  import('../../../node_modules/framer-motion').then((x) => {
    MotionDivOriginal = x.motion.div
  })
}

const MotionDiv = React.forwardRef<HTMLDivElement, HTMLMotionProps<'div'>>(
  (props, ref) => <MotionDivOriginal {...props} ref={ref} />
)

export default MotionDiv
