import React, { Children, useCallback } from 'preact/compat'
import { VNode } from 'preact'
import AnimateHeight from './components/AnimateHeight'
import { useSelector, useDispatch } from './store/hooks'
import useSizeObserver from '../../utils/useSizeObserver'
import Crossfade from './components/Crossfade'
import { Breakpoint } from '~scripts/cssHelpers/grid'
import { UiBackAction } from './store/types/ui'
import HTMLNode from '~scripts/components/HTMLNode'
import MotionDiv from '~scripts/components/MotionDiv'

const colClasses = (columnWidths: Partial<{ [bp in Breakpoint]: number }>) => {
  const result = []
  for (const bp in columnWidths) {
    if (bp === 'xs') {
      result.push('col-' + Math.max(0, columnWidths[bp as Breakpoint]!))
    } else {
      result.push(
        'col-' + bp + '-' + Math.max(0, columnWidths[bp as Breakpoint]!)
      )
    }
  }
  return result.join(' ')
}

const leftColumnWidths: { [bp in Breakpoint]: number } = {
  xs: -0,
  sm: -0,
  md: 0,
  lg: 0,
  xl: 0,
}
const leftColumnClasses = colClasses(leftColumnWidths)

const middleColumnWidths: { [bp in Breakpoint]: number } = {
  xs: 12,
  sm: 12,
  md: 5,
  lg: 4,
  xl: 4,
}
const middleColumnClasses = colClasses(middleColumnWidths)

const asideColumnWidth: { [bp in Breakpoint]: number } = {
  xs: 0,
  sm: 0,
  md: 0,
  lg: 0,
  xl: 0,
}
const asideColumnWideWidth: { [bp in Breakpoint]: number } = {
  xs: 12,
  sm: 12,
  md: 12,
  lg: 8,
  xl: 8,
}

const gapWidths: { [bp in Breakpoint]: [number, number] } = {
  xs: [0, 0],
  sm: [0, 0],
  md: [1, 1],
  lg: [1, 0],
  xl: [1, 0],
}

const MainLayout: React.FunctionComponent<{
  children: [VNode, VNode, VNode]
}> = ({ children }) => {
  const [leftAside, menu, rightAside] = children
  const location = useSelector((state) => state.ui.location)
  const breakpoint = useSelector((state) => state.ui.breakpoint)
  const wideAside =
    !!location &&
    !!location.aside &&
    (!location.children || !location.children.length)

  const [rowWidth, _rowHeight, rowRef] = useSizeObserver()
  const columnWidth = rowWidth / 12

  const [leftWidth, leftHeight, leftRef] = useSizeObserver()
  const [middleWidth, middleHeight, middleRef] = useSizeObserver()
  const middleMarginLeft = wideAside
    ? -(columnWidth * Math.abs(leftColumnWidths[breakpoint])) + 'px'
    : '0px'
  const hideMiddle = wideAside && leftColumnWidths[breakpoint] < 0
  const showLeft = leftColumnWidths[breakpoint] > 0
  const middleMarginRight =
    columnWidth * gapWidths[breakpoint][wideAside ? 1 : 0] + 'px'
  const middleMarginTop = wideAside && showLeft ? leftHeight + 54 + 'px' : '0px'

  const asideWidth =
    (wideAside ? asideColumnWideWidth : asideColumnWidth)[breakpoint] *
    columnWidth
  const minHeight = (wideAside ? 1 : 0) * (leftHeight + middleHeight + 54 * 3)

  const dispatch = useDispatch<UiBackAction>()
  const goBack = useCallback(
    (e: MouseEvent) => {
      e.preventDefault()
      dispatch({ type: 'ui/back' })
    },
    [dispatch]
  )

  const { search: searchNode } = useSelector((state) => state.definition)
  const { search } = useSelector((state) => state.ui)

  return (
    <AnimateHeight>
      <Crossfade currentKey={search ? 'search' : location ? 'menu' : 'empty'}>
        {!search && !menu && <div key="empty" />}
        {!!search && (
          <div class="container py-3 py-sm-5" key="search">
            <HTMLNode node={searchNode} />
          </div>
        )}
        {!!location && (
          <div class="container" key="menu" style={{ minHeight }}>
            <div class="row" style={{ flexWrap: 'nowrap' }} ref={rowRef}>
              <div class={leftColumnClasses}>
                <div class="py-5">
                  <div ref={leftRef}>{leftAside}</div>
                </div>
              </div>
              <MotionDiv
                className={middleColumnClasses}
                initial={{
                  marginLeft: 0 + 'px',
                  marginTop: 0 + 'px',
                  opacity: 1,
                  visibility: 'visible',
                }}
                animate={{
                  marginLeft: middleMarginLeft,
                  marginTop: middleMarginTop,
                  opacity: hideMiddle ? 0 : 1,
                  visibility: hideMiddle ? 'hidden' : 'visible',
                }}
                style={{ marginRight: middleMarginRight }}
                transition={{ type: 'tween' }}
              >
                <MotionDiv
                  className="py-3 py-sm-5"
                  style={{ overflow: 'hidden', width: '100%' }}
                >
                  <div ref={middleRef}>{menu}</div>
                </MotionDiv>
              </MotionDiv>
              <Crossfade currentKey={asideWidth ? rightAside.key : 'empty'}>
                {!!asideWidth ? (
                  <div
                    className="col"
                    style={{
                      width: asideWidth + 'px',
                      maxWidth: asideWidth + 'px',
                      flexBasis: asideWidth + 'px',
                    }}
                  >
                    <div class="py-3 py-sm-5">
                      {wideAside && hideMiddle && (
                        <nav class="nav nav-column-arrows mb-3">
                          <a
                            href="#"
                            class="nav-link nav-link-back"
                            onClick={goBack}
                          >
                            {location && location.label}
                          </a>
                          <hr />
                        </nav>
                      )}
                      {rightAside}
                    </div>
                  </div>
                ) : (
                  <div />
                )}
              </Crossfade>
            </div>
          </div>
        )}
      </Crossfade>
    </AnimateHeight>
  )
}

export default MainLayout
