import ResizeObserver from 'resize-observer-polyfill'
import watchElements from '~scripts/utils/watchElements'
import { TabsData } from './types'
import { render } from 'preact'
import React from 'preact/compat'
import Tabs from './Tabs'

type DomTabsData = {
  tabsWrap: HTMLElement
  tabs: HTMLElement[]
  currentTab: HTMLElement
  index: number
  tabsData: TabsData
}

class TabsContainer {
  wrap: HTMLElement
  navWrap: HTMLElement
  resizeObserver = new ResizeObserver(() => {
    this.updateHeight()
  })

  constructor(el: HTMLElement) {
    this.wrap = el
    this.wrap.classList.add('tabs-wrap--initialized')
    this.navWrap = document.createElement('div')
    this.wrap.insertBefore(this.navWrap, this.wrap.firstChild)
  }

  updateHeight() {
    const { currentTab, tabsWrap } = this.dataFromDOM
    if (currentTab) {
      const { height } = currentTab.getBoundingClientRect()
      tabsWrap.style.height = height + 'px'
    }
  }

  get index(): number {
    const { index } = this.dataFromDOM
    return index
  }
  set index(nextIndex: number) {
    const { tabsWrap } = this.dataFromDOM
    tabsWrap.setAttribute('data-current', nextIndex.toString())
  }

  get dataFromDOM(): DomTabsData {
    const tabsWrap = this.wrap.querySelector<HTMLElement>('.tabs')!
    const tabs = Array.from(tabsWrap.children) as HTMLElement[]
    const index = Math.min(
      tabs.length - 1,
      Math.max(0, parseFloat(tabsWrap.getAttribute('data-current') || '0'))
    )
    const tabsData = tabs.map((tab) => ({
      title: tab.getAttribute('data-title') || '',
      icon: tab.getAttribute('data-icon') || undefined,
    }))
    return {
      tabsWrap,
      tabs,
      currentTab: tabs[index],
      index,
      tabsData,
    }
  }

  lastData?: DomTabsData
  hasChanged(): false | DomTabsData {
    const lastData = this.lastData
    const data = this.dataFromDOM
    this.lastData = data

    if (!lastData) {
      return data
    }
    let hasNotChanged = true
    hasNotChanged = hasNotChanged && data.tabsWrap === lastData.tabsWrap
    hasNotChanged =
      hasNotChanged &&
      data.tabs.length === lastData.tabs.length &&
      data.tabs.every((tab, i) => lastData.tabs[i] === tab)
    hasNotChanged = hasNotChanged && data.currentTab === lastData.currentTab
    hasNotChanged = hasNotChanged && data.index === lastData.index
    hasNotChanged =
      hasNotChanged &&
      data.tabsData.length === lastData.tabsData.length &&
      data.tabsData.every(
        (tab, i) =>
          lastData.tabsData[i].icon === tab.icon &&
          lastData.tabsData[i].title === tab.title
      )
    if (hasNotChanged) {
      return false
    }
    return data
  }

  update() {
    const data = this.hasChanged()
    if (!data) {
      return
    }
    data.tabs.forEach((tab, i) => {
      tab.classList.toggle('tab--active', i === data.index)
      tab.classList.toggle('tab--hidden', i !== data.index)
    })
    const currentTab = data.currentTab
    this.resizeObserver.disconnect()
    if (currentTab) {
      this.resizeObserver.observe(currentTab)
    }
    render(
      <Tabs
        current={data.index}
        onClick={(i) => (this.index = i)}
        tabsData={data.tabsData}
      />,
      this.navWrap,
      this.navWrap.lastChild as Element
    )
    data.tabsWrap.classList.add('animate-height')
    this.updateHeight()
    setTimeout(() => {
      data.tabsWrap.classList.remove('animate-height')
    }, 400)
  }

  destroy() {
    render(<div />, this.navWrap, this.navWrap.lastChild as Element)
    this.wrap.removeChild(this.navWrap)
    this.wrap.classList.remove('tabs-wrap--initialized')
  }
}

const tabContainers = new WeakMap<HTMLElement, TabsContainer>()
const update = (el: HTMLElement) => {
  const wrap = el.closest<HTMLElement>('.tabs-wrap')
  if (!wrap) {
    return
  }
  let tabContainer = tabContainers.get(wrap)
  if (!tabContainer) {
    tabContainer = new TabsContainer(wrap)
    tabContainers.set(wrap, tabContainer)
  }
  tabContainer.update()
}
const unmount = (el: HTMLElement) => {
  const wrap = el.closest<HTMLElement>('.tabs-wrap')
  if (!wrap) {
    return
  }
  const tabContainer = tabContainers.get(wrap)
  if (tabContainer) {
    tabContainer.destroy()
    tabContainers.delete(el)
  }
}

watchElements<HTMLElement>(
  '.tabs',
  {
    attributeFilter: ['data-current', 'data-title', 'data-icon'],
    subtree: true,
  },
  {
    update,
    unmount,
  }
)
