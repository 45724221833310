import { UiState, UiAction } from '../types/ui'
import { Reducer } from 'preact/hooks/src'
import { DefinitionSetAction } from '../types/definition'
import { currentBreakpoint } from '~scripts/cssHelpers/grid'
import { showMobileMenu } from '../actions/uiActions'

const initialState: UiState = {
  location: undefined,
  breakpoint: currentBreakpoint(),
  mobileMenu: showMobileMenu(),
  search: false,
}

const uiReducer: Reducer<UiState, UiAction | DefinitionSetAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case 'definition/set':
      return {
        ...state,
        location: undefined,
      }
    case 'ui/back':
      return {
        ...state,
        search: false,
        location: state.location ? state.location.parent : undefined,
      }
    case 'ui/set_location':
      return {
        ...state,
        search: false,
        location: action.location,
      }
    case 'ui/onresize':
      return {
        ...state,
        location: undefined,
        search: false,
        breakpoint: action.breakpoint,
        mobileMenu: action.mobileMenu,
      }
    case 'ui/set_search':
      return {
        ...state,
        location: undefined,
        search: action.state,
      }
    default:
      return state
  }
}

export default uiReducer
