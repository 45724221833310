import React from 'preact/compat'
import useSizeObserver from '../../../utils/useSizeObserver'
import MotionDiv from '~scripts/components/MotionDiv'

const AnimateHeight: React.FunctionComponent = ({ children }) => {
  const [width, height, contentRef] = useSizeObserver()

  return (
    <MotionDiv
      animate={{ height }}
      transition={{ type: 'tween' }}
      style={{ position: 'relative', overflow: 'hidden' }}
    >
      <div
        ref={contentRef}
        style={{ position: 'absolute', top: '0px', left: '0px', right: '0px' }}
      >
        {children}
      </div>
    </MotionDiv>
  )
}

export default AnimateHeight
