export const gridBreakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1300,
} as const
export type Breakpoint = keyof typeof gridBreakpoints
type ContainerDefinition = Partial<
  {
    readonly [bp in Breakpoint]: number
  }
>

export const containerMaxWidths: ContainerDefinition = {
  sm: 540,
  md: 720,
  lg: 960,
  xl: 1140,
}
export const containerWideMaxWidths: ContainerDefinition = {
  sm: gridBreakpoints.md,
  md: gridBreakpoints.lg,
  lg: gridBreakpoints.xl,
  xl: 1600,
}
export const gridGutterWidth = 30

export const currentBreakpoint = (): Breakpoint => {
  const { innerWidth } = window
  let currentBp: Breakpoint = 'xs'
  for (const bp in gridBreakpoints) {
    if (gridBreakpoints[bp as Breakpoint] <= innerWidth) {
      currentBp = bp as Breakpoint
    }
  }
  return currentBp
}

export const containerWidth = (wide = false, inner = true): number => {
  const width = (wide ? containerWideMaxWidths : containerMaxWidths)[
    currentBreakpoint()
  ]
  const maxWidth = wide
    ? window.innerWidth - gridGutterWidth
    : window.innerWidth
  return Math.max(
    0,
    Math.min(maxWidth, width || maxWidth) - (inner ? gridGutterWidth : 0)
  )
}

export const pageMargin = (wide = false): number => {
  return (window.innerWidth - containerWidth(wide)) / 2
}
