import React, { useCallback } from 'preact/compat'
import { TabsData } from './types'
import SelectPicker from '~scripts/components/SelectPicker'
import useSizeObserver from '~scripts/utils/useSizeObserver'

const Tab: React.FunctionComponent<{
  title: string
  icon?: string
  onClick: (index: number) => void
  index: number
  current: number
}> = ({ title, icon, onClick, index, current }) => {
  const handleClick = useCallback(
    (e: MouseEvent) => {
      e.preventDefault()
      onClick(index)
    },
    [onClick, index]
  )
  return (
    <li class="nav-item">
      <a
        class={'nav-link' + (index === current ? ' active' : '')}
        href="#"
        onClick={handleClick}
      >
        {icon && <i class={'fal fa-' + icon} />}
        {title}
      </a>
    </li>
  )
}

export type TabsProps = {
  tabsData: TabsData
  current: number
  onClick: (index: number) => void
}
const Tabs: React.FunctionComponent<TabsProps> = ({
  tabsData,
  current,
  onClick,
}) => {
  const [wrapWidth, wrapHeight, wrapRef] = useSizeObserver()
  const [navWidth, navHeight, navRef] = useSizeObserver()
  const mobile = navWidth > wrapWidth

  return (
    <div className={'tabs-nav-wrap' + (mobile ? ' mobile' : '')} ref={wrapRef}>
      <ul class="nav nav-tabs mb-4 mb-lg-5" ref={navRef}>
        {tabsData.map(({ title, icon }, i) => (
          <Tab
            key={i}
            title={title}
            icon={icon}
            onClick={onClick}
            index={i}
            current={current}
          />
        ))}
      </ul>
      <SelectPicker value={current} onChange={onClick}>
        {tabsData.map(({ title, icon }, i) => (
          <option value={i} key={i}>
            {title}
          </option>
        ))}
      </SelectPicker>
    </div>
  )
}

export default Tabs
