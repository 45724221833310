import React, { useState, useEffect } from 'preact/compat'

const HTMLNode: React.FunctionComponent<{ node?: HTMLElement | null }> = ({
  node,
}) => {
  const [wrap, setWrap] = useState<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!node || !wrap) {
      return
    }
    if (node.parentElement === wrap) {
      return
    }
    if (node.parentElement) {
      node.parentElement.removeChild(node)
    }
    wrap.appendChild(node)
  }, [node, wrap])

  return <div ref={setWrap}></div>
}

export default HTMLNode
