import ResizeObserver from 'resize-observer-polyfill'
import watchElements from '~scripts/utils/watchElements'
import getPositionParent from '~scripts/utils/getPositionParent'

const resizeObserver = new ResizeObserver((entries) => {
  for (const entry of entries) {
    for (const el of entry.target.querySelectorAll<HTMLElement>('.bg-cover')) {
      updateElement(el)
    }
  }
})

const updateElement = (el: HTMLElement) => {
  const [width = 16, height = 9] = (el.getAttribute('data-proportions') || '')
    .split(':')
    .map(parseFloat)
  const [fx = 0.5, fy = 0.5] = (el.getAttribute('data-focuspoint') || '')
    .split(':')
    .map(parseFloat)
  const parent = getPositionParent(el)
  const { width: pWidth = 16, height: pHeight = 9 } = parent
    ? parent.getBoundingClientRect()
    : {}
  const matchWidth = width / height < pWidth / pHeight
  const elWidth = !matchWidth ? (width / height) * pHeight : pWidth
  const elHeight = matchWidth ? (height / width) * pWidth : pHeight
  el.style.width = elWidth + 'px'
  el.style.height = elHeight + 'px'
  el.style.marginTop = Math.round((elHeight - pHeight) * -fy) + 'px'
  el.style.marginLeft = Math.round((elWidth - pWidth) * -fx) + 'px'
}

watchElements<HTMLElement>(
  '.bg-cover',
  {
    attributeFilter: ['data-proportions', 'data-focuspoint'],
  },
  {
    mount: (el) => {
      const parent = getPositionParent(el)
      parent && resizeObserver.observe(parent)
      el.style.position = 'absolute'
      updateElement(el)
    },
    update: updateElement,
    unmount: (el) => {
      const parent = getPositionParent(el)
      parent && resizeObserver.unobserve(parent)
    },
  }
)
