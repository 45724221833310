const getPositionParent = (el: Element): Element | undefined => {
  if (
    el.parentElement &&
    ['relative', 'absolute', 'fixed'].includes(
      getComputedStyle(el.parentElement).position
    )
  ) {
    return el.parentElement
  }
  if (el.parentElement === document.body) {
    return el.parentElement
  }
  if (!el.parentElement) {
    return undefined
  }
  return getPositionParent(el.parentElement)
}

export default getPositionParent
