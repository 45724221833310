import { combineReducers, createStore } from 'redux'
import reducers from './reducers'
import { AppState, AppAction } from './types'
import { onResizeAction } from './actions/uiActions'
import $ from 'jquery'

const App = combineReducers(reducers)

const store = createStore<AppState, AppAction, {}, {}>(
  App,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)
$(window).resize(() => {
  store.dispatch(onResizeAction())
})
export default store
